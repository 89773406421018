const API_DISCOVERY = 'http://115.79.29.63:18381/api/v1/';
const LINK_DISCOVERY = 'http://115.79.29.63:18381/';
const API_LMS = 'https://pc53lms.elitelearning.vn';
const STUDIO_URL = process.env.REACT_APP_STUDIO_URL;
const LEARNING_URL = 'https://pc53learner.elitelearning.vn';
const PREVIEW_URL = 'https://preview.elitelearning.vn';
const API_STUDIO = 'https://pc53cms.elitelearning.vn';
const LMS_URL = API_LMS;

// for Sign In with Google only. Use the same url for all sites.
// Please be cautious in PROD
const DEFAULT_SITE_LMS = "https://pc53lms.elitelearning.vn"; // MUST be https://lms.elitelearning.vn for all sites in PROD

const LINK_INSTRUCTOR = (courseId) =>
   LMS_URL + `/courses/${courseId}/instructor`;
const LINK_COURSEWARE = (courseId) =>
   LMS_URL + `/courses/${courseId}/courseware`;

const LOGIN = API_LMS + "/user_api/v1/account/login_session/";

const REGISTER = API_LMS + "/user_api/v1/account/registration/";

const REGISTER_V2 = API_LMS + "/api/elite/v1/register/";

const REGISTER_V3 = API_LMS + "/api/elite/v1/user_register/";

const LOGIN_ACCESS_TOKEN = API_LMS + '/oauth2/elite_access_token/';

const GET_PROFILE = (username) => {
   return API_LMS + "/api/user/v1/accounts/" + username;
};
const PATCH_PROFILE = (username) => {
   return API_LMS + "/api/user/v1/accounts/" + username;
};
const UPDATE_PROFILE = API_LMS + "/api/v2/update_profile/";

const UPDATE_PROFILE_V2 = API_LMS + "/api/elite/v1/update_user_profile/";
const GET_THIRD_PARTY_ACCOUNTS =
   API_LMS + "/api/elite/v1/get_third_party_accounts/";
const GET_ACCOUNT_SETTING = API_LMS + "/api/elite/v1/account_setting/";

const GET_ACCESS_TOKEN = API_LMS + "/oauth2/access_token/";

const OAUTH2_LOGIN = API_LMS + "/oauth2/login/";

const GET_COURSES_LIST = (organization) => {
   return (
      API_DISCOVERY +
      "search/all/facets/" +
      "?selected_facets=" +
      organization +
      "&content_type=courserun"
   );
};

// const FORGOT_PASSWORD = API_LMS + '/account/password';
const FORGOT_PASSWORD = API_LMS + "/api/elite/v1/forgot_password";

const GET_COURSE_DETAILS = (key) => {
   return API_DISCOVERY + "course_runs/" + key + "/";
};

const GET_INSTRUCTOR_DETAILS = (uid) => {
   return API_DISCOVERY + "people/" + uid + "/";
};

const GET_COURSE_OF_INSTRUCTOR = API_DISCOVERY + "course_runs/";

const GET_USERNAME = API_LMS + "/api/elite/v1/me/";

const GET_OUTLINE = API_LMS + "/api/courses/v1/blocks/";

const GET_ENROLLMENT = API_LMS + "/api/enrollment/v1/enrollment/v2/";

const GET_BUTTON_STATUS = (courseId) => {
   return API_LMS + "/api/v2/get_register_status/?course_id=" + courseId;
};

const GET_NUMBER_OF_REGISTERD_USERS = (courseId) => {
   return API_LMS + "/api/v2/get_register_count/?course_id=" + courseId;
};

const REGISTER_COURSE = API_LMS + "/api/v2/pre_enroll/";
const LMS_LOGOUT_LINK = API_LMS + "/oauth2/revoke_token/";
const LMS_LOGOUT_LINK_TWO = API_LMS + "/logout";
const LMS_LOGOUT_LINK_HASH = LEARNING_URL + "/#/logout";

const LMS_DASHBOARD_LINK = API_LMS + "/dashboard";

const POST_CONTACT = API_LMS + "/api/v2/send_contact_form/";

const RESET_PASSWORD = API_LMS + "/password_reset/";

// const UPLOAD_AVATAR = (userName) => {
//    return API_LMS + '/api/user/v1/accounts/' + userName + '/image';
// };

const UPLOAD_AVATAR = API_LMS + "/api/elite/v1/accounts/image";

const GET_COURSE_ENROLLMENT = (username) => {
   return API_LMS + `/api/mobile/v1/users/${username}/course_enrollments/`;
};

const GET_COURSE_DASHBOARD = () => {
   return API_LMS + `/api/v2/user_enrolled_courses/`;
};

const GET_ALL_COURSES = API_LMS + `/api/elite/v1/get_all_courses/`;

const GET_PAST_COURSES = API_LMS + `/api/elite/v1/get_all_past_courses/`;

const GET_LEARNER_COMPONENTS =
   API_LMS + "/api/elite/v1/get_learner_components/";

//Admin
const GET_REQUEST_LIST = (params) => {
   let linkAPI =
      API_LMS +
      `/api/v2/get_registered_user_list/?page=${params.page}&page_size=${params.page_size}`;
   if (params.status) {
      linkAPI = linkAPI + `&status=${params.status}`;
   }
   if (params.course_id) {
      linkAPI = linkAPI + `&course_id=${params.course_id}`;
   }
   if (params.start_date) {
      linkAPI = linkAPI + `&start_date=${params.start_date}`;
   }
   if (params.end_date) {
      linkAPI = linkAPI + `&end_date=${params.end_date}`;
   }
   if (params.sort_fields) {
      linkAPI = linkAPI + `&sort_fields=${params.sort_fields}`;
   }
   if (
      params.descending !== null &&
      params.descending !== void 0 &&
      params.descending !== ""
   ) {
      linkAPI = linkAPI + `&descending=${params.descending}`;
   }
   return linkAPI;
};
const GET_CONTACT_LIST = (params) => {
   return (
      API_LMS + `/api/v2/get_contact_email_list/?page_size=${params.page_size}`
   );
};
const REPLY_CONTACT_FORM = API_LMS + `/api/v2/reply_contact_form/`;
const ENROLL_UNENROLL_USER_TO_ONE_COURSE =
   API_LMS + `/api/v2/change_enrollment_status/`;
const VIEW_USER_DETAILS = (username) => {
   return API_LMS + `/api/user/v1/accounts?username=${username}`;
};

//Get courses
const GET_COURSES = LINK_DISCOVERY + `api/v1/course_runs/`;
//resetpassword
const SET_NEW_PASSWORD = (token) => {
   return API_LMS + `/api/v2/set_new_password/${token}/`;
};

const COURSE_DETAILS = (courseKey) => {
   return API_LMS + `/api/courses/v1/courses/${courseKey}`;
};

const GET_USER_PROFILE_V2 = API_LMS + "/api/elite/v1/get_user_profile/";

const CHANGES_PASSWORD = API_LMS + "/api/elite/v1/change_password/";

const ACTIVE_ACCOUNT = API_LMS + "/activate/";

const CHECK_ROLE_USER = API_LMS + "/api/elite/v1/instructor_of_course/";

const LEANER_CHANGE_FIRST_GUIDE = (status) =>
   API_LMS + `/api/elite/v1/learner_change_first_intro_guide/${status}`;

// For Third Parties Authorization
const THIRD_PARTY_GOOGLE_SIGN_IN =
   DEFAULT_SITE_LMS + "/auth/login/google-oauth2/?auth_entry=login";
const THIRD_PARTY_GOOGLE_REGISTER =
   DEFAULT_SITE_LMS + "/auth/login/google-oauth2/?auth_entry=register";
const THIRD_PARTY_GOOGLE_COMPLETE_OAUTH =
   DEFAULT_SITE_LMS + "/auth/complete/google-oauth2/?";
const IS_ENABLED_GOOGLE = process.env.REACT_APP_ENABLE_GOOGLE_SIGN_IN;

const THIRD_PARTY_GOOGLE_EXCHANGE_TOKEN = DEFAULT_SITE_LMS + "/oauth2/elite_exchange_access_token/google-oauth2/";
const THIRD_PARTY_LINK_ACCOUNT = (provider) => {
   return DEFAULT_SITE_LMS + "/api/elite/v1/auth/elite_connect/" + provider + '/';
}

// new learner
const GET_SUGGEST_COURSES_LEARNER = API_LMS + '/api/elite/v1/get_public_courses/';
const GET_ACTIVE_COURSES_LEARNER = API_LMS + `/api/elite/v1/active_course_matrix/`;
const GET_PAST_COURSES_LEARNER = API_LMS + `/api/elite/v1/past_course_matrix/`;
const GET_CERTIFICATE_LIST = API_LMS + `/api/elite/v1/certificates`;
const GET_INSTRUCTOR_MATRIX = API_LMS + "/api/elite/v1/instructors";
const GET_PRE_REQUISITE_COURSES = (courseId) =>
   API_LMS + `/api/elite/v1/prerequisite_courses/${courseId}`;
const GET_CERTIFICATE_DETAIL_LEARNER = (code) =>
   API_STUDIO + `/api/courses/certificates/code/${code}`;
const VIEW_CERTIFICATE_DETAIL_LEARNER = (courseId) =>
   API_STUDIO + `/api/courses/certificates/details/${courseId}`;
const CHANGE_PUBLIC_COURSE_ENROLLMENT =
   API_LMS + `/api/elite/v1/change_public_course_enrollment/`;
const GET_NOTIFICATION_LIST = (courseId) =>
   API_LMS +
   `/api/elite/v1/course_info_update/${courseId}/`;
// Studio
const GET_COURSE_MATRIX = API_STUDIO + "/api/courses/course_matrix/";
const GET_COURSE_DETAIL = (courseId) =>
   API_STUDIO + `/api/courses/course_detail/${courseId}`;
const RERUN_COURSE = (courseId) =>
   API_STUDIO + `/api/courses/create_course/${courseId}`;
const CREATE_COURSE = API_STUDIO + "/api/courses/create_course/";
const CREATE_LIBRARY = API_LMS + "/api/elite/v1/create_library/";
const GET_LIBRARIES = API_LMS + `/api/elite/v1/list_libraries/`;
const GET_ACTIVE_COURSES = API_STUDIO + `/api/courses/active_course_matrix/`;
const GET_ARCHIVED_COURSES = API_STUDIO + `/api/courses/archived_course_matrix/`;
const GET_STUDIO_COMPONENTS = API_LMS + "/api/elite/v1/get_studio_components/";
const CAN_REQUEST_TRIAL =
   API_STUDIO + "/api/courses/service/can_request_trial_service/main_service";
const GET_COURSE_SETTING_DETAIL = (courseId) =>
   API_STUDIO + `/api/courses/settings/details/${courseId}/`;
const GET_COURSE_SETTING_ADVANCED = (courseId) =>
   API_STUDIO + `/api/courses/settings/advanced/${courseId}/`;
const CREATE_ASSET = (courseId) =>
   API_STUDIO + `/api/courses/assets/${courseId}/`;
const GET_GRADING_DETAIL = (courseId) =>
   API_STUDIO + `/api/courses/settings/grading/${courseId}/`;
const CREATE_UPLOADED_VIDEO = (courseId) =>
   API_STUDIO + `/api/courses/videos/${courseId}`;
const GET_CERTIFICATE_DETAIL = (courseId) =>
   API_STUDIO + `/api/courses/certificates/${courseId}/`;
const UPDATE_XBLOCK = (courseId) =>
   API_STUDIO + `/api/courses/xblock/${courseId}`;
const UPDATE_LIBRARY_CONTENTS_XBLOCK = (courseId) =>
   API_STUDIO + `/api/courses/update_elite_library_content/${courseId}`;
const GET_MY_COURSES = (courseId) =>
   API_STUDIO + `/api/courses/get_active_courses/${courseId}`;
const CREATE_XBLOCK = API_STUDIO + `/api/courses/xblock/`;
const SWITCH_CERTIFICATE = (courseId) =>
   API_STUDIO + `/api/courses/certificates/switch_certificate/${courseId}`;
const CHECK_ENABLE_CERTIFICATE = (courseId) =>
   API_STUDIO +
   `/api/courses/certificates/check_enable_certificate/${courseId}/`;
const ACTIVE_CERTIFICATE = (courseId) =>
   API_STUDIO + `/api/courses/certificates/activation/${courseId}/`;
const GET_CHAPTERS = (courseId) =>
   API_STUDIO + `/api/courses/xblock/chapters/${courseId}`;
const GET_XBLOCK_OUTLINE = (blockId) =>
   API_STUDIO + `/api/courses/xblock/outline/${blockId}`;
const DELETE_XBLOCK = (blockId) =>
   API_STUDIO + `/api/courses/xblock/${blockId}`;
const GET_CATEGORIES_LIST = API_STUDIO + `/api/courses/templated_course/category/matrix/`;
const GET_TEMPLATES_COURSE_LIST = API_STUDIO + `/api/courses/templated_course/template/matrix/`;
const GET_TEXTBOOKS_MATRIX = (course_key) =>
   API_STUDIO +
   `/api/courses/textbooks/matrix/${course_key}`;
const CREATE_TEXTBOOK = (courseId) =>
   API_STUDIO + `/api/courses/textbooks/create/${courseId}`;
const DELETE_TEXTBOOK = (courseId, textbookId) =>
   API_STUDIO + `/api/courses/textbooks/${courseId}/${textbookId}`;
const CREATE_NOTIFICATION = (courseId) =>
   API_STUDIO + `/api/courses/course_info_update/${courseId}/`;
const GET_NOTIFICATION_MATRIX = (courseId) =>
   API_STUDIO + `/api/courses/course_info_update/${courseId}/`;
const DELETE_NOTIFICATION = (courseId, notiId) =>
   API_STUDIO + `/api/courses/course_info_update/${courseId}/${notiId}`;
const CREATE_LIVE_ROOM = API_STUDIO + "/api/courses/create_live_room/";
const GET_MEETING_INFO = API_STUDIO + "/api/courses/get_meeting_info/";
const END_MEETING_ROOM = API_STUDIO + "/api/courses/end_meeting_room/";
const JOIN_LIVE_ROOM = (meeting_id, password) => {
   let linkAPI = API_STUDIO + `/api/courses/join_live_room`;
   if (meeting_id) {
      linkAPI = linkAPI + `?meeting_id=${meeting_id}`;
   }
   if (meeting_id && password) {
      linkAPI = linkAPI + `?meeting_id=${meeting_id}&password=${password}`;
   }
   return linkAPI;
}
const DELETE_MEETING = API_STUDIO + '/api/courses/delete_meeting/';
const GET_CERTIFICATE_LINK = (courseId, mode) => API_STUDIO + `/api/courses/certificates/view/${courseId}`;
const GET_XBLOCK_CHILDREN = (courseId) => API_STUDIO + `/api/courses/xblock/children/${courseId}`;
const GET_ALL_DIRECTORIES = API_STUDIO + `/api/courses/directories/`;
const CREATE_DIRECTORY = API_STUDIO + '/api/courses/directories/';
const GET_DIRECTORY_DETAIL = (directoryUid) => API_STUDIO + `/api/courses/directories/${directoryUid ? `${directoryUid}` : ''}`;
const EDIT_DIRECTORY = (directoryUid) => API_STUDIO + `/api/courses/directories/${directoryUid}`;
const DELETE_DIRECTORY = (directoryUid) => API_STUDIO + `/api/courses/directories/${directoryUid}`;
const MOVE_MEDIA_TO_DIRECTORY = (mediaUid, directoryUid) => API_STUDIO + `/api/courses/move_media_to_directory/${mediaUid}/${directoryUid}`
const GET_MEDIA_MATRIX = API_STUDIO + `/api/courses/media_libraries/`;
const CREATE_MEDIA = API_STUDIO + '/api/courses/media_libraries/';
const UPLOAD_VIDEO_TO_S3 = () => API_STUDIO + '/api/courses/upload_video_to_s3/';
const UPLOAD_ASSET_TO_S3 = () => API_STUDIO + '/api/courses/upload_asset_to_s3/';
const UPLOAD_ASSET_TO_S3_VER2 = (directoryId) => API_STUDIO + `/api/courses/aws_s3_presigned_directory/${directoryId}`;
const CREATE_DIRECTORY_S3 = API_STUDIO + '/api/courses/aws_s3_directories/';
const EDIT_DIRECTORY_S3 = (directoryUid) => API_STUDIO + `/api/courses/aws_s3_directories/${directoryUid}`;
const DELETE_DIRECTORY_S3 = (directoryUid) => API_STUDIO + `/api/courses/aws_s3_directories/${directoryUid}`;
const EDIT_INFO_MEDIA = (mediaUid) => API_STUDIO + `/api/courses/change_media_info/${mediaUid}`;
const DELETE_MEDIA = (mediaUid) => API_STUDIO + `/api/courses/media_libraries/${mediaUid}`;
const GET_MEETING_LIST = API_STUDIO + `/api/courses/get_meeting_list/`;
const CREATE_NEW_MEETING = API_STUDIO + '/api/courses/create_new_meeting/';
const GET_ROOM_MEETING_INFO = API_STUDIO + '/api/courses/get_room_info/';
const UPDATE_NEW_MEETING = API_STUDIO + '/api/courses/update_meeting/';
const GET_MEETING_USER_LIST = API_STUDIO + `/api/courses/get_meeting_user_list/`;
const CREATE_MEETING_ROOM = API_STUDIO + '/api/courses/room/create/';
const UPDATE_MEETING_ROOM = API_STUDIO + '/api/courses/room/update/';
const DELETE_MEETING_ROOM = API_STUDIO + '/api/courses/room/delete/';
const GET_MEETING_ROOM_LIST = API_STUDIO + `/api/courses/room/matrix/`;
const GET_MEETING_ROOM_HISTORY = API_STUDIO + `/api/courses/room/history/`;
const GET_MEETING_ROOM_DETAILS = API_STUDIO + '/api/courses/room/details/';
const GET_ALL_MEETING_LIST = API_STUDIO + `/api/courses/get_all_meeting_list/`;
const GET_COURSE_TEAM_LIST = (courseId) => API_STUDIO + `/api/courses/course_teams/${courseId}`;
const CREATE_COURSE_TEAM = (courseId) => API_STUDIO + `/api/courses/course_teams/${courseId}`;

const GET_LIBRARY_INFO = API_LMS + '/api/elite/v1/library_info/';
const GET_TRANSCRIPT_LANGUAGES = (edxVideoId) => API_STUDIO + `/api/courses/transcripts/languages/${edxVideoId}`;
const GET_TRANSCRIPT_LANGUAGE_DETAILS = (blockId) => API_STUDIO + `/api/courses/transcripts/language_details/${blockId}`;
const UPLOAD_TRANSCRIPTION = (lang) => API_STUDIO + `/api/courses/transcripts/upload/${lang}`;
const DELETE_TRANSCRIPTION = (edxVideoId, lang) => API_STUDIO + `/api/courses/transcripts/delete/${edxVideoId}/${lang}`;
const DOWNLOAD_TRANSCRIPTION = (lang) => API_STUDIO + `/api/courses/transcripts/download/${lang}`;
const UPLOAD_HANDOUT = (blockId) => API_STUDIO + `/api/courses/transcripts/upload_handout/${blockId}`;
const CHECKLIST_GET_QUALITY_COURSE = (courseId) => API_STUDIO + `/api/courses/v1/quality/${courseId}/`;
const CHECKLIST_GET_VALIDATION_COURSE = (courseId) => API_STUDIO + `/api/courses/v1/validation/${courseId}/`;
const GET_CSRFTOKEN = API_LMS + '/api/elite/v1/get_csrf_token/';
const GET_COURSE_OUTLINE_PREVIEW = (courseId) => API_STUDIO + `/api/courses/course_outline_preview/${courseId}`;
const GET_CALENDAR_EVENT_LIST = API_LMS + '/api/elite/v1/calendar/list';
const SYNC_EVENTS = API_LMS + '/api/elite/v1/calendar/sync_events';
const CREATE_XBLOCK_ATTACHMENTS = (xblockId) => API_STUDIO + `/api/courses/xblock_attachments/${xblockId}`;
const CREATE_MULTI_XBLOCK_COMPONENTS = (usage_key) =>
   API_STUDIO + `/api/courses/create_multi_components/${usage_key}`;

// Instructor
const GET_STUDENTS = API_LMS + `/api/elite/v1/get_students/`;
const GET_STUDENTS_IN_COURSE = API_LMS + `/api/elite/v1/students_in_course/`;
const ENROLL_STUDENTS = API_LMS + "/api/elite/v1/students_enrollment";
const GET_GRADE_BOOK = API_LMS + `/api/elite/v1/students_gradebook/`;
const GET_GRADES = API_LMS + "/api/elite/v1/grades";
const GET_INSTRUCTOR_COURSES = API_LMS + `/api/elite/v1/instructor_course_list/`;
const GET_GRADE_PROGRESS = API_LMS + "/api/elite/v1/student_progress ";
const GET_GRADING_DETAILS = API_LMS + "/api/elite/v1/get_grading_details ";
const CERTIFICATE_FOR_STUDENTS =
   API_LMS + "/api/elite/v1/generate_certificates_for_students";
const GET_COURSE_ASSESSMENT_COMPONENTS = (courseId) =>
   API_LMS + `/api/elite/v1/assessments/components/${courseId}`;
const GET_STUDENT_MATRIX = (assessmentId) =>
   API_LMS +
   `/api/elite/v1/assessments/students/${assessmentId}`;
const GET_STUDENT_ASSESSMENT_FILES = (assessmentId) =>
   API_LMS +
   `/api/elite/v1/assessments/file_matrix/${assessmentId}`;
const DOWNLOAD_ASSESSMENT_FILE = (assessmentFileUid) =>
   API_LMS + `/api/elite/v1/assessments/download_file/${assessmentFileUid}`;
const SCORE_ASSESSMENT_FOR_STUDENT = (assessmentId) =>
   API_LMS + `/api/elite/v1/assessments/make_score/${assessmentId}`;
const DELETE_ASSESSMENTS_BY_USERS = (assessmentId) =>
   API_LMS + `/api/elite/v1/assessments/delete_files_by_users/${assessmentId}`;
// const GET_LEARNER_GRADE_IMFORMATION = (courseId, email) => API_LMS + `/api/elite-v1/leanrer_grade_info/${courseId}?email=${email}`;
const GET_LEARNER_GRADE_IMFORMATION = (courseId) => API_LMS + `/api/elite-v1/leanrer_grade_info/${courseId}`;

// New instructor page
// const GET_STUDENTS_ANSWER = (pageNo, pageSize) => API_LMS + `/api/elite/v1/students_answer/?page=${pageNo}&page_size=${pageSize}`;
// const GET_STUDENT_ANSWER_DETAILS = API_LMS + `/api/elite/v1/student_answer_details`;
const RESET_ATTEMPT_PROBLEM = API_LMS + `/api/elite/v1/reset_student_attempts`;
const RESET_ATTEMPT_ASSESSMENT = (id) => API_LMS + `/api/elite/v1/assessments/reset_student_attempts/${id}`;

// New instructor page v2
// const GET_STUDENTS_ANSWER = (pageNo, pageSize, courseId) => API_LMS + `/api/elite-v1/learner_grade_list/${courseId}?page=${pageNo}&page_size=${pageSize}`;
const GET_STUDENTS_ANSWER = (courseId) => API_LMS + `/api/elite-v1/learner_grade_list/${courseId}`;
const GET_STUDENT_ANSWER_DETAILS = (courseId) => API_LMS + `/api/elite-v1/learner_grade_details/${courseId}`;

// Course ware
const GET_COURSE_WARE = API_LMS + `/api/elite/v1/get_course_info/`;
const GET_COURSE_HANDOUTS = API_LMS + `/api/elite/v1/get_handouts/`;

const GET_COURSES_ABOUT = API_LMS + `/api/elite/v1/get_course_about/`;
const GET_COURSE_INSTRUCTORS = API_LMS + `/api/elite/v1/instructors_in_course/`;

const GET_LESSON_COURSE_DETAILS = (courseId) =>
   API_LMS + `/api/elite-v1/course_details/${courseId}`;
const GET_LESSON_UNIT_DETAILS = (blockId) =>
   API_LMS + `/api/elite-v1/unit_details/${blockId}`;
const XBLOCK_HANDLER = (blockId, handlerName, sufffix) =>
   API_LMS +
   `/api/elite/v1/xblock/${blockId}/handler/${handlerName}/${sufffix || ""}`;
const MARK_COMPLETED = (blockId) =>
   API_LMS + `/api/elite/v1/xblock/${blockId}/handler/publish_completion`;
const GET_LESSON_BLOCK_DETAILS = (blockId) =>
   API_LMS +
   `/api/elite/v1/xblock_details/${blockId}`;
const GET_LESSON_CHAPTER_DETAILS = (blockId) =>
   API_LMS + `/api/elite-v1/chapter_details/${blockId}`;
const GET_LESSON_UNIT_COMPONENT_DETAILS = (blockId) =>
   API_LMS + `/api/elite-v1/component_details/${blockId}`;
const XBLOCK_TRACKING = (blockId, sufffix) =>
   API_LMS + `/api/elite/v1/xblock_tracking/${blockId}/${sufffix || ""}`;
const GET_LESSON_COURSE_OUTLINE = (courseId) =>
   API_LMS + `/api/elite/v1/course_outline/${courseId}`;
const UPLOAD_ASSESSMENT_FILE = (blockId) =>
   API_LMS + `/api/elite/v1/assessments/upload_file/${blockId}`;
const SUBMIT_ASSESSMENT = (blockId) =>
   API_LMS + `/api/elite/v1/xblock/${blockId}/handler/student_submission`;
const DELETE_ASSESSMENT_FILE = (fileUid) =>
   API_LMS + `/api/elite/v1/assessments/delete_file/${fileUid}`;
const GET_ASSESSMENT = (blockId) =>
   API_LMS + `/api/elite/v1/xblock/${blockId}/handler/get_submission`;
const RESET_LIBRARY_CONTENT = (blockId) =>
   API_LMS + `/api/elite-v1/reset_library_content/${blockId}`;
const RESET_ELITE_LIBRARY_CONTENT = (blockId) =>
   API_LMS + `/api/elite-v1/reset_elite_library_content/${blockId}`;
const CHECK_PROBLEM = (blockId) =>
   API_LMS + `/api/elite-v1/problem_check/${blockId}`;
const CHECK_LIBRARY_CONTENT = (blockId) =>
   API_LMS + `/api/elite-v1/library_content_check/${blockId}`;
const CHECK_ELITE_LIBRARY_CONTENT = (blockId) =>
   API_LMS + `/api/elite-v1/elite_library_content_check/${blockId}`;

// Payment
const CREATE_USER_PAYMENT = API_LMS + `/api/elite/v1/create_user_payment/`;
const REQUEST_TRIAL_INSTRUCTOR =
   API_STUDIO + `/api/courses/service/request_trial_instructor/`;
const GET_PAYMENT_LINK = API_LMS + `/api/elite-v1/vnpay/pay`;
const GET_PAYMENT_RESULT = (search) =>
   API_LMS + `/api/elite-v1/vnpay/return${search}`;
const GET_PRICE_SERVICE_DETAIL = serviceCode => API_STUDIO + `/api/courses/service/pricing_service_details/${serviceCode}`;
const GET_PRICE_MATRIX = API_STUDIO + '/api/courses/service/matrix/?page_size=100';

// sso_token
const GET_SSO_TOKEN = API_LMS + `/api/elite/v1/sso_token/`;

export const API_CONST = {
   LMS_URL,
   LEARNING_URL,
   API_STUDIO,
   API_LMS,
   STUDIO_URL,
   LOGIN,
   REGISTER,
   GET_PROFILE,
   GET_ACCESS_TOKEN,
   OAUTH2_LOGIN,
   GET_COURSES_LIST,
   GET_COURSE_DETAILS,
   FORGOT_PASSWORD,
   GET_INSTRUCTOR_DETAILS,
   GET_COURSE_OF_INSTRUCTOR,
   PATCH_PROFILE,
   GET_USERNAME,
   GET_OUTLINE,
   GET_ENROLLMENT,
   GET_BUTTON_STATUS,
   REGISTER_COURSE,
   GET_NUMBER_OF_REGISTERD_USERS,
   LMS_LOGOUT_LINK,
   LMS_LOGOUT_LINK_TWO,
   LMS_DASHBOARD_LINK,
   POST_CONTACT,
   RESET_PASSWORD,
   UPLOAD_AVATAR,
   UPDATE_PROFILE,
   UPDATE_PROFILE_V2,
   GET_COURSE_ENROLLMENT,
   LINK_DISCOVERY,
   REGISTER_V2,
   GET_REQUEST_LIST,
   GET_CONTACT_LIST,
   REPLY_CONTACT_FORM,
   ENROLL_UNENROLL_USER_TO_ONE_COURSE,
   VIEW_USER_DETAILS,
   GET_COURSES,
   GET_COURSE_DASHBOARD,
   GET_ALL_COURSES,
   GET_PAST_COURSES,
   SET_NEW_PASSWORD,
   COURSE_DETAILS,
   LINK_INSTRUCTOR,
   LINK_COURSEWARE,
   GET_USER_PROFILE_V2,
   CHANGES_PASSWORD,
   ACTIVE_ACCOUNT,
   CHECK_ROLE_USER,
   LMS_LOGOUT_LINK_HASH,
   THIRD_PARTY_GOOGLE_SIGN_IN,
   THIRD_PARTY_GOOGLE_REGISTER,
   THIRD_PARTY_GOOGLE_COMPLETE_OAUTH,
   THIRD_PARTY_GOOGLE_EXCHANGE_TOKEN,
   THIRD_PARTY_LINK_ACCOUNT,
   REGISTER_V3,
   IS_ENABLED_GOOGLE,
   GET_COURSE_MATRIX,
   CREATE_COURSE,
   CREATE_LIBRARY,
   GET_LIBRARIES,
   GET_ACTIVE_COURSES,
   GET_ARCHIVED_COURSES,
   GET_STUDIO_COMPONENTS,
   CAN_REQUEST_TRIAL,
   GET_STUDENTS,
   GET_STUDENTS_IN_COURSE,
   ENROLL_STUDENTS,
   GET_GRADE_BOOK,
   GET_GRADES,
   GET_INSTRUCTOR_COURSES,
   GET_GRADE_PROGRESS,
   GET_COURSE_DETAIL,
   GET_GRADING_DETAILS,
   CERTIFICATE_FOR_STUDENTS,
   GET_COURSE_ASSESSMENT_COMPONENTS,
   GET_STUDENT_MATRIX,
   GET_STUDENT_ASSESSMENT_FILES,
   DOWNLOAD_ASSESSMENT_FILE,
   SCORE_ASSESSMENT_FOR_STUDENT,
   DELETE_ASSESSMENTS_BY_USERS,
   GET_STUDENTS_ANSWER,
   GET_STUDENT_ANSWER_DETAILS,
   RESET_ATTEMPT_PROBLEM,
   RESET_ATTEMPT_ASSESSMENT,

   RERUN_COURSE,
   GET_COURSE_WARE,
   GET_COURSES_ABOUT,
   GET_COURSE_INSTRUCTORS,
   GET_COURSE_HANDOUTS,
   CREATE_USER_PAYMENT,
   REQUEST_TRIAL_INSTRUCTOR,
   GET_COURSE_SETTING_DETAIL,
   GET_COURSE_SETTING_ADVANCED,
   CREATE_ASSET,
   GET_GRADING_DETAIL,
   CREATE_UPLOADED_VIDEO,
   GET_CERTIFICATE_DETAIL,
   UPDATE_XBLOCK,
   UPDATE_LIBRARY_CONTENTS_XBLOCK,
   GET_MY_COURSES,
   CREATE_XBLOCK,
   SWITCH_CERTIFICATE,
   CHECK_ENABLE_CERTIFICATE,
   GET_CHAPTERS,
   GET_XBLOCK_OUTLINE,
   DELETE_XBLOCK,
   ACTIVE_CERTIFICATE,
   GET_CATEGORIES_LIST,
   GET_TEXTBOOKS_MATRIX,
   GET_TEMPLATES_COURSE_LIST,
   CREATE_TEXTBOOK,
   DELETE_TEXTBOOK,
   CREATE_NOTIFICATION,
   GET_NOTIFICATION_MATRIX,
   DELETE_NOTIFICATION,
   GET_LEARNER_COMPONENTS,
   GET_CERTIFICATE_LINK,
   CREATE_LIVE_ROOM,
   GET_MEETING_INFO,
   END_MEETING_ROOM,
   JOIN_LIVE_ROOM,
   DELETE_MEETING,
   GET_XBLOCK_CHILDREN,
   GET_ALL_DIRECTORIES,
   CREATE_DIRECTORY,
   GET_DIRECTORY_DETAIL,
   EDIT_DIRECTORY,
   DELETE_DIRECTORY,
   GET_MEDIA_MATRIX,
   CREATE_MEDIA,
   UPLOAD_VIDEO_TO_S3,
   UPLOAD_ASSET_TO_S3,
   EDIT_INFO_MEDIA,
   DELETE_MEDIA,
   GET_MEETING_LIST,
   CREATE_NEW_MEETING,
   GET_ROOM_MEETING_INFO,
   UPDATE_NEW_MEETING,
   GET_MEETING_USER_LIST,
   GET_LIBRARY_INFO,
   GET_TRANSCRIPT_LANGUAGES,
   GET_TRANSCRIPT_LANGUAGE_DETAILS,
   UPLOAD_TRANSCRIPTION,
   DELETE_TRANSCRIPTION,
   DOWNLOAD_TRANSCRIPTION,
   UPLOAD_HANDOUT,
   LEANER_CHANGE_FIRST_GUIDE,
   GET_SUGGEST_COURSES_LEARNER,
   GET_ACTIVE_COURSES_LEARNER,
   GET_PAST_COURSES_LEARNER,
   GET_LESSON_COURSE_DETAILS,
   GET_LESSON_UNIT_DETAILS,
   XBLOCK_HANDLER,
   MARK_COMPLETED,
   GET_LESSON_BLOCK_DETAILS,
   GET_LESSON_CHAPTER_DETAILS,
   GET_LESSON_UNIT_COMPONENT_DETAILS,
   GET_LESSON_COURSE_OUTLINE,
   UPLOAD_ASSESSMENT_FILE,
   SUBMIT_ASSESSMENT,
   DELETE_ASSESSMENT_FILE,
   GET_ASSESSMENT,
   CHECKLIST_GET_QUALITY_COURSE,
   CHECKLIST_GET_VALIDATION_COURSE,
   GET_CERTIFICATE_LIST,
   GET_INSTRUCTOR_MATRIX,
   GET_PRE_REQUISITE_COURSES,
   GET_CERTIFICATE_DETAIL_LEARNER,
   VIEW_CERTIFICATE_DETAIL_LEARNER,
   GET_THIRD_PARTY_ACCOUNTS,
   GET_CSRFTOKEN,
   GET_COURSE_OUTLINE_PREVIEW,
   GET_CALENDAR_EVENT_LIST,
   SYNC_EVENTS,
   XBLOCK_TRACKING,
   MOVE_MEDIA_TO_DIRECTORY,
   GET_PAYMENT_LINK,
   GET_PAYMENT_RESULT,
   GET_PRICE_SERVICE_DETAIL,
   GET_PRICE_MATRIX,
   CHANGE_PUBLIC_COURSE_ENROLLMENT,
   GET_NOTIFICATION_LIST,
   RESET_LIBRARY_CONTENT,
   RESET_ELITE_LIBRARY_CONTENT,
   CHECK_PROBLEM,
   CHECK_LIBRARY_CONTENT,
   CHECK_ELITE_LIBRARY_CONTENT,
   CREATE_XBLOCK_ATTACHMENTS,
   GET_ACCOUNT_SETTING,
   CREATE_MEETING_ROOM,
   UPDATE_MEETING_ROOM,
   GET_MEETING_ROOM_LIST,
   GET_MEETING_ROOM_HISTORY,
   GET_MEETING_ROOM_DETAILS,
   DELETE_MEETING_ROOM,
   GET_ALL_MEETING_LIST,
   CREATE_MULTI_XBLOCK_COMPONENTS,
   GET_COURSE_TEAM_LIST,
   CREATE_COURSE_TEAM,
   LOGIN_ACCESS_TOKEN,
   GET_LEARNER_GRADE_IMFORMATION,
   GET_SSO_TOKEN,
   UPLOAD_ASSET_TO_S3_VER2,
   CREATE_DIRECTORY_S3,
   DELETE_DIRECTORY_S3,
   EDIT_DIRECTORY_S3,
};
