// import "@babel/polyfill";
// Luan comment all above libs as it is not compatible with current core-js version.


import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browser } from './browser';
import * as serviceWorker from './serviceWorker';
import store from './store/Store';
import { API_CONST } from './API';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

if (browser.isValid()) {
	require('./scss/styles.scss');
	require('intro.js/introjs.css');
	require('react-image-crop/dist/ReactCrop.css')
	const App = React.lazy(() => import('./App'));

	ReactDOM.render(
		<Suspense fallback={''}>
			<Provider store={store}>
				<App />
			</Provider>
		</Suspense>
		, document.getElementById('root'));

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
} else {
	window.location = API_CONST.LEARNING_URL + '/unsupported-browser';
}
