import { Action } from "../Action";
const initialState = {
   errorCode: null,
}

export const errorReducer = (state = initialState, action) => {
   switch (action.type) {
      case Action.SET_ERROR:
         if (action.errorCode !== state.errorCode) {
            return { ...state, errorCode: action.errorCode }
         }
         return state;
      default:
         return state
   }
}
