import ServicesStatusModel from "../../utils/UserServicesStatus/ServicesStatusModel";
import { Action } from "../Action";

// User statuses:
// * is_active - user đã kích hoạt tài khoản
// * is_active_teacher - user mua gói Elite và thành giáo viên
// * is_staff - là staff của hệ thống(thấy dc hết các khóa học mà k cần phải là giáo viên)
// * is_superuser - admin full quyền, vào dc trang admin của backend luôn

const initialState = {
   userProfile: null,
   accountSetting: null,
   servicesStatus: new ServicesStatusModel(),
   isMobile: false,
}

export const commonReducer = (state = initialState, action) => {
   switch (action.type) {
      case Action.SET_USER_PROFILE:
         if (action.userProfile !== state.userProfile || action.servicesStatus !== state.servicesStatus) {
            return {
               ...state,
               userProfile: action.userProfile,
               servicesStatus: {
                  ...(state.servicesStatus || {}),
                  ...(action.servicesStatus || {}),
               },
            }
         }
         return state;
      case Action.SET_ACCOUNT_SETTING:
         return { ...state, accountSetting: action.payload };
      case Action.SET_IS_MOBILE:
         return { ...state, isMobile: action.payload };
      default:
         return state
   }
}
