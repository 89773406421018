const Bowser = require("bowser");

const isWebview = () => {
   let standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);
   if (ios) {
      if (!standalone && !safari) {
         // ios webview
         return true;
      }
   } else {
      if (userAgent.includes('wv')) {
         // android webview
         return true;
      }
   };
   return false;
}

const isValid = () => {
   // is webview
   if (isWebview()) {
      return true;
   }

   // is browser
   const browser = Bowser.getParser(window.navigator.userAgent);
   return browser.satisfies({
      chrome: ">=49",
      firefox: ">=50",
      safari: ">=11",
      // ie: ">=11",
      edge: ">=15",
      opera: ">=38",
      samsung_internet: ">=5",
      android: ">=5",
      uc: ">=12.12",
      qq: ">=10.4",
      mobile: {
         safari: '>=11',
         opera: ">=62",
      },
   });
}

export const browser = {
   isValid,
}

// security performance ux, chức năng ko ổn định
