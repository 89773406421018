import { LearningPathwayStatus } from "./StatusModels/LearningPathwayStatus";
import { TrialStatus } from "./StatusModels/TrialStatus";

class ServicesStatusModel {
   constructor() {
      this.trialStatus = TrialStatus.NOT_YET;
      this.learningPathwayStatus = LearningPathwayStatus.NOT_YET;

      this.startedDate = '';
      this.expiryDate = '';
      this.datesLeft = null;
      this.exprired = false;
      this.mainServiceRequested = false;
   }
}

export default ServicesStatusModel;